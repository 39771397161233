import firebase from "firebase";

export class FirebaseSingleton {
  private static instance: firebase.app.App;

  public static getInstance(firebaseSettings: any): firebase.app.App {
    if (!FirebaseSingleton.instance) {
      this.instance = firebase.initializeApp(firebaseSettings);
    }

    return FirebaseSingleton.instance;
  }
}
