import React from "react";
import "@shopify/polaris/dist/styles.css";
import { Card, Layout } from "@shopify/polaris";
import { Line } from "react-chartjs-2";
import { VennService } from "../service/VennService";

type SalesReportGraphProps = {
  vennService: VennService;
  storekey: string;
  analyticsLink: string;
  currencySymbol: string;
  settings: any;
};

type SalesReportGraphState = {
  salesReport: any;
};

export class SalesReportGraph extends React.Component<
  SalesReportGraphProps,
  SalesReportGraphState
> {
  constructor(props: SalesReportGraphProps) {
    super(props);
    this.state = { salesReport: undefined };
  }

  async componentDidMount() {
    const salesReport = await this.props.vennService.getSalesReport(
      this.props.storekey
    );
    this.setState({ salesReport: salesReport });
  }

  componentWillUnmount() {}

  onClickGoToAnalytics = () => {
    if (this.props.settings.isOnboarding) {
      alert("This feature will become functional once the mobile app goes live.")
    } else {
      window.open(this.props.analyticsLink);
    }
  };

  render() {
    return (
      <Layout.Section>
        <Card
          sectioned
          title="Sales Summary"
          primaryFooterAction={{
            content: "Advanced Analytics",
            onAction: this.onClickGoToAnalytics,
          }}
        >
          <Card.Section>
            <Line
              data={this.chartData(this.state.salesReport)}
              options={{
                legend: {
                  display: true,
                  position: "top",
                },
                responsive: true,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: (value, index, values) => {
                          if (parseInt(value.toString()) >= 1000) {
                            return `${
                              this.props.currencySymbol || ""
                            }${value
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                          } else {
                            return `${this.props.currencySymbol || ""}${value}`;
                          }
                        },
                      },
                    },
                  ],
                },
              }}
            />
          </Card.Section>
        </Card>
      </Layout.Section>
    );
  }

  chartData(salesReport: any) {
    if (salesReport === undefined) {
      return {
        labels: [],
        datasets: [
          {
            label: "Gross sales",
            backgroundColor: "rgba(93,108,190,0.25)",
            borderColor: "rgb(93,108,190)",
            data: [],
            borderWidth: 3,
          },
        ],
      };
    }
    return {
      labels: salesReport.map((dataPoint: any) => dataPoint.title).reverse(),
      datasets: [
        {
          label: "Gross sales",
          backgroundColor: "rgba(93,108,190,0.25)",
          borderColor: "rgb(93,108,190)",
          data: salesReport.map((dataPoint: any) => dataPoint.value).reverse(),
          borderWidth: 3,
        },
      ],
    };
  }
}
