import React from "react";
import "@shopify/polaris/dist/styles.css";
import {
  Card,
  DropZone,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import "./EditModuleView.css";
import "./EditImageWithPaddingView.css";
import firebase from "firebase";
import { areEqualObjects, deepCloneObject } from "../utils/ObjectUtils";
import { VennService } from "../service/VennService";
import { parseProductHandle } from "../utils/VennUtils";

type EditImageWithPaddingViewProps = {
  initialAppModule: any;
  storekey: string;
  vennService: VennService;
  firebaseClient: firebase.app.App;
  handleModuleUpdate: (updatedModule: any) => void;
};

type EditImageWithPaddingViewState = {
  currentAppModule: any;
  initialHandle: string;
  currentHandle: string;
};

export class EditImageWithPaddingView extends React.Component<
  EditImageWithPaddingViewProps,
  EditImageWithPaddingViewState
> {
  constructor(props: EditImageWithPaddingViewProps) {
    super(props);
    this.state = {
      initialHandle: "",
      currentHandle: "",
      currentAppModule: props.initialAppModule,
    };
  }

  async componentDidMount() {
    await this.updateHandle();
  }

  async componentDidUpdate(
    prevProps: Readonly<EditImageWithPaddingViewProps>,
    prevState: Readonly<EditImageWithPaddingViewState>,
    snapshot?: any
  ) {
    if (
      !areEqualObjects(prevProps.initialAppModule, this.props.initialAppModule)
    ) {
      this.setState({ currentAppModule: this.props.initialAppModule });
      await this.updateHandle();
    }
  }

  async updateHandle() {
    const linkType = this.props.initialAppModule.attributes?.link?.type;
    const linkId = this.props.initialAppModule.attributes?.link?.payload;
    if (linkId && linkType) {
      if (linkType === "product") {
        console.log(`Fetching product for id: ${linkId}`);
        const product = await this.props.vennService.getProductById(
          this.props.storekey,
          linkId
        );
        if (!product) {
          this.setState({ initialHandle: "", currentHandle: "" });
          alert("Product handle is invalid.");
          return;
        }
        console.log(`Fetched product ${JSON.stringify(product)}`);
        const handle = parseProductHandle(product.productUrl);
        this.setState({ initialHandle: handle, currentHandle: handle });
      } else if (linkType === "category") {
        console.log(`Fetching collection for id: ${linkId}`);
        const collection = await this.props.vennService.getCollectionById(
          this.props.storekey,
          linkId
        );
        if (!collection) {
          this.setState({ initialHandle: "", currentHandle: "" });
          alert("Collection handle is invalid.");
          return;
        }
        console.log(`Fetched collection ${JSON.stringify(collection)}`);
        this.setState({
          initialHandle: collection.handle,
          currentHandle: collection.handle,
        });
      }
    } else {
      console.log("No link id specified");
      this.setState({ initialHandle: "", currentHandle: "" });
    }
  }

  handlePreviewButtonClicked = async () => {
    const currentAppModule = deepCloneObject(this.state.currentAppModule);
    let linkType = this.state.currentAppModule.attributes?.link?.type;

    // if they pasted the whole URL to something, parse out the handle
    let handle = this.state.currentHandle.toLowerCase().trim();
    if (handle.includes("/collections/")) {
      handle = handle.split("/collections/")[1];
    }
    if (handle.includes("/products/")) {
      handle = handle.split("/products/")[1];
    }

    if (linkType) {
      console.log(`Converting ${linkType} handle ${handle}`);
      if (linkType === "product") {
        const product = await this.props.vennService.getProductByHandle(
          this.props.storekey,
          handle
        );
        currentAppModule.attributes.link.payload = product.id;
      } else if (linkType === "category") {
        const collection = await this.props.vennService.getCollectionByHandle(
          this.props.storekey,
          handle
        );
        currentAppModule.attributes.link.payload = collection?.id
          ? collection.id
          : "";
      }
    }

    console.log(`Saving module value: ${JSON.stringify(currentAppModule)}`);
    this.props.handleModuleUpdate(currentAppModule);
  };

  handleLinkTypeChange = (value: string) => {
    console.log(`Saving link type: ${value}`);
    const updatedObject = deepCloneObject(this.state.currentAppModule);
    if (value === "none") {
      updatedObject.attributes.link = undefined;
    } else {
      updatedObject.attributes.link = {
        type: value,
        payload: "",
      };
    }
    this.setState({ currentAppModule: updatedObject });
  };

  handleLinkPayloadChange = (value: string) => {
    console.log(`Saving link handle to state: ${value}`);
    this.setState({ currentHandle: value });
  };

  validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  handleDropZoneDrop = async (
    files: File[],
    acceptedFiles: File[],
    rejectedFiles: File[]
  ) => {
    const file = files[0];
    const fileType = file?.type;
    if (this.validImageTypes.includes(fileType)) {
      console.log(`File: ${files[0]?.type}`);
      if (file.size > 250000) {
        alert(
          `Warning: this file is over 250kb in size and can degrade the performance of the app home page. If possible try to compress the image below 250kb to keep the home page quick and responsive.`
        );
      }

      const directoryName = new Date().toISOString().substring(0, 10);

      const storageRef = this.props.firebaseClient.storage().ref();
      const imageRef = storageRef.child(
        `${this.props.storekey}/${directoryName}/${file.name}`
      );

      await imageRef.put(file);
      const downloadUrl = await imageRef.getDownloadURL();
      console.log(`Uploaded the new image file to: ${downloadUrl}`);

      // now get the image dimensions by loading the file into an image
      let fr = new FileReader();
      let currentAppModule = this.state.currentAppModule;
      let this1 = this;
      fr.onload = function () {
        let img = new Image();

        img.onload = function () {
          console.log(
            `Image dimensions height: ${img.height} width: ${img.width}`
          );
          const multiplier = img.height / img.width;
          console.log(`Multiplier: ${multiplier}`);

          const currentAppModuleObject = deepCloneObject(currentAppModule);
          currentAppModuleObject.attributes.imageUrl = downloadUrl;
          currentAppModuleObject.heightMultiplier = multiplier;
          this1.setState({ currentAppModule: currentAppModuleObject });
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(file);
    } else {
      alert(
        "Unsupported file type. Please select a jpeg, gif or png image file."
      );
    }
  };

  renderHandleComponent(props: any) {
    const linkType = props.linkType;
    const currentHandle = props.currentHandle;
    const handleLinkPayloadChange = props.handleLinkPayloadChange;
    let handleLabelText = "";
    switch (linkType) {
      case "category":
        handleLabelText = "Collection Handle";
        break;
      default:
        handleLabelText = "Product Handle";
    }

    if (linkType) {
      return (
        <TextField
          value={currentHandle}
          onChange={handleLinkPayloadChange}
          label={handleLabelText}
          type="text"
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const moduleHasChanged =
      !areEqualObjects(
        this.props.initialAppModule,
        this.state.currentAppModule
      ) || this.state.initialHandle !== this.state.currentHandle;

    const linkType = this.state.currentAppModule.attributes.link?.type;

    const fileUpload = <DropZone.FileUpload />;

    const this1 = this;
    return (
      <Card
        title={"Edit Banner Image View"}
        sectioned
        secondaryFooterActions={[
          {
            content: "Preview Updates",
            onAction(): void {
              this1.handlePreviewButtonClicked();
            },
            disabled: !moduleHasChanged,
          },
        ]}
      >
        <div>
          <FormLayout>
            <div className={"imagePreviewRow"}>
              <div
                className={"imagePreviewColumn"}
                style={{ marginRight: "8px" }}
              >
                <span className={"Polaris-Label__Text"}>Image Upload</span>
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <DropZone onDrop={this.handleDropZoneDrop}>
                      {fileUpload}
                    </DropZone>
                  </div>
                </div>
              </div>
              <div
                className={"imagePreviewColumn"}
                style={{ marginLeft: "8px" }}
              >
                <span className={"Polaris-Label__Text"}>Image Preview</span>
                <img
                  src={this.state.currentAppModule.attributes.imageUrl}
                  style={{ width: "100%" }}
                  alt={""}
                />
              </div>
            </div>
            <Select
              label="Link Type"
              options={[
                { label: "None", value: "none" },
                { label: "Collection", value: "category" },
                { label: "Product", value: "product" },
              ]}
              onChange={this.handleLinkTypeChange}
              value={linkType}
            />
            <this.renderHandleComponent
              linkType={linkType}
              currentHandle={this.state.currentHandle}
              handleLinkPayloadChange={this.handleLinkPayloadChange}
            />
          </FormLayout>
        </div>
      </Card>
    );
  }
}
