import React from "react";
import "@shopify/polaris/dist/styles.css";
import "./ImageWithPaddingView.css";
import "./ModuleView.css";

type ImageWithPaddingViewProps = {
  appModule: any;
  isSelected: boolean;
};

export class ImageWithPaddingView extends React.Component<
  ImageWithPaddingViewProps
> {
  render() {
    let classes = "imageWithPaddingModuleContainer";
    if (this.props.isSelected) {
      classes = classes + " selected";
    } else {
      classes = classes + " unselected";
    }
    return (
      <div className={classes}>
        <img src={this.props.appModule.attributes.imageUrl} alt={""} />
      </div>
    );
  }
}
