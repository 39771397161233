import React from "react";
import "@shopify/polaris/dist/styles.css";
import { Button } from "@shopify/polaris";
import "./EditModuleView.css";
import MonacoEditor from "react-monaco-editor/lib/editor";

type EditPlaceholderViewProps = {
  appModule: any;
  handleModuleUpdate: (updatedModule: any) => void;
};

type EditPlaceholderViewState = {
  editorText: string;
};

export class EditPlaceholderView extends React.Component<
  EditPlaceholderViewProps,
  EditPlaceholderViewState
> {
  constructor(props: EditPlaceholderViewProps) {
    super(props);
    this.state = { editorText: "" };
  }

  componentDidMount(): void {
    this.setState({
      editorText: this.formatAppModuleForEditing(this.props.appModule),
    });
  }

  componentDidUpdate(
    prevProps: Readonly<EditPlaceholderViewProps>,
    prevState: Readonly<EditPlaceholderViewState>,
    snapshot?: any
  ): void {
    const propsAppModuleFormatted = this.formatAppModuleForEditing(
      this.props.appModule
    );
    if (
      propsAppModuleFormatted !==
      this.formatAppModuleForEditing(prevProps.appModule)
    ) {
      this.setState({ editorText: propsAppModuleFormatted });
    }
  }

  handleOnChange = (newValue: string) => {
    this.setState({ editorText: newValue });
  };

  handlePreviewButtonClicked = () => {
    console.log(`Saving module value: ${this.state.editorText}`);
    const moduleValue = JSON.parse(this.state.editorText);
    if (moduleValue) {
      this.props.handleModuleUpdate(moduleValue);
    }
  };

  formatAppModuleForEditing(appModule: any): string {
    if (!appModule) {
      return "";
    } else {
      return JSON.stringify(appModule, null, 2);
    }
  }

  render() {
    const editorTextHasChanged =
      this.formatAppModuleForEditing(this.props.appModule) !==
      this.state.editorText;

    return (
      <div>
        <MonacoEditor
          height="600px"
          theme="default"
          language="json"
          onChange={this.handleOnChange}
          value={this.state.editorText}
        />

        <Button
          onClick={this.handlePreviewButtonClicked}
          disabled={!editorTextHasChanged}
        >
          Preview Updates
        </Button>
      </div>
    );
  }
}
