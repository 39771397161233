import React from "react";
import "@shopify/polaris/dist/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame, Layout, Loading, Page } from "@shopify/polaris";
import { VennService } from "../service/VennService";
import { AnalyticsScreen } from "./AnalyticsScreen";
import queryString from "query-string";

type AnalyticsScreenRootProps = {};

type AnalyticsScreenRootState = {
  settings: any;
};

export class AnalyticsScreenRoot extends React.Component<
  AnalyticsScreenRootProps,
  AnalyticsScreenRootState
> {
  queryParams = queryString.parse(window.location.search);
  isRunningLocally = this.queryParams.bypassSecurity !== undefined && this.queryParams.isRunningLocally === "true";
  shop = this.queryParams.shop as string;
  vennService = new VennService(this.isRunningLocally, window.location.search);

  constructor(props: AnalyticsScreenRootProps) {
    super(props);
    this.state = {
      settings: undefined,
    };
  }

  handleSettingsChange = (settings: any) => {
    this.setState({ settings: settings });
  };

  async componentDidMount() {
    const result = await this.vennService.getSettings(this.shop);
    console.log("Result", { result });
    if (result) {
      console.log("Result!");
      if (result.billingRedirect) {
        console.log("Billing stuff");
        window.top.location.href = result.result.billingRedirect;
        return;
      } else {
        console.log("Setting state to stuff");
        this.setState({
          settings: result,
        });
      }
    }
  }

  render() {
    if (!this.state.settings) {
      // show loading bar if settings are still being fetched
      return (
        <AppProvider i18n={enTranslations}>
          <Page title="Venn Apps">
            <Layout>
              <Frame>
                <Loading />
              </Frame>
            </Layout>
          </Page>
        </AppProvider>
      );
    }
    return (
      <AppProvider i18n={enTranslations}>
        <AnalyticsScreen
          vennService={this.vennService}
          shop={this.shop}
          handleSettingsChange={this.handleSettingsChange}
          settings={this.state.settings}
        />
      </AppProvider>
    );
  }
}
