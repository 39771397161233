import React from "react";
import "@shopify/polaris/dist/styles.css";
import "./SpacerView.css";
import "./ModuleView.css";

type SpacerViewProps = {
  appModule: any;
  isSelected: boolean;
};

export class SpacerView extends React.Component<SpacerViewProps> {
  render() {
    let classes = "spacerModuleContainer";
    if (this.props.isSelected) {
      classes = classes + " selected";
    } else {
      classes = classes + " unselected";
    }
    const spacerBackgroundColor = this.props.appModule.attributes
      .spacerBackgroundColor?.hex;
    return (
      <div
        className={classes}
        style={{
          height: this.props.appModule.height,
          backgroundColor: spacerBackgroundColor,
        }}
      ></div>
    );
  }
}
