import React from "react";
import "@shopify/polaris/dist/styles.css";
import "./EditModuleView.css";
import firebase from "firebase";
import { VennService } from "../service/VennService";
import { Card, DropZone, FormLayout } from "@shopify/polaris";

type EditTopLogoViewProps = {
  isTopLogoSelected: boolean;
  storeInfo: any;
  storekey: string;
  vennService: VennService;
  firebaseClient: firebase.app.App;
  handleLogoUpdate: (logoUrl: string) => void;
};

export class EditTopLogoView extends React.Component<EditTopLogoViewProps> {
  validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  handleDropZoneDrop = async (
    files: File[],
    acceptedFiles: File[],
    rejectedFiles: File[]
  ) => {
    const file = files[0];
    const fileType = file?.type;
    if (this.validImageTypes.includes(fileType)) {
      console.log(`File: ${files[0]?.type}`);
      if (file.size > 250000) {
        alert(
          `Warning: this file is over 250kb in size and can degrade the performance of the app home page. If possible try to compress the image below 250kb to keep the home page quick and responsive.`
        );
      }

      const directoryName = new Date().toISOString().substring(0, 10);

      const storageRef = this.props.firebaseClient.storage().ref();
      const imageRef = storageRef.child(
        `${this.props.storekey}/${directoryName}/${file.name}`
      );

      await imageRef.put(file);
      const downloadUrl = await imageRef.getDownloadURL();
      console.log(`Uploaded the new image file to: ${downloadUrl}`);

      // now get the image dimensions by loading the file into an image
      let fr = new FileReader();
      let this1 = this;
      fr.onload = function () {
        let img = new Image();

        img.onload = function () {
          console.log(
            `Image dimensions height: ${img.height} width: ${img.width}`
          );
          const multiplier = img.height / img.width;
          console.log(`Multiplier: ${multiplier}`);

          this1.props.handleLogoUpdate(downloadUrl);
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(file);
    } else {
      alert(
        "Unsupported file type. Please select a jpeg, gif or png image file."
      );
    }
  };

  render() {
    if (!this.props.isTopLogoSelected) {
      return null;
    }

    const fileUpload = <DropZone.FileUpload />;

    return (
      <Card
        title={"Edit Top Bar Logo"}
        sectioned
        // secondaryFooterActions={[
        //   {
        //     content: "Preview Updates",
        //     onAction(): void {
        //       this1.handlePreviewButtonClicked();
        //     },
        //     disabled: !moduleHasChanged,
        //   },
        // ]}
      >
        <div>
          <FormLayout>
            <div>
              <div>
                <span className={"Polaris-Label__Text"}>Image Upload</span>
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <DropZone onDrop={this.handleDropZoneDrop}>
                      {fileUpload}
                    </DropZone>
                  </div>
                </div>
              </div>
            </div>
          </FormLayout>
        </div>
      </Card>
    );
  }
}
