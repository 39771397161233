import React from "react";
import "@shopify/polaris/dist/styles.css";
import {
  Card,
  FooterHelp,
  FormLayout,
  Layout,
  Link,
  Page,
  TextContainer,
} from "@shopify/polaris";
import { VennService } from "../service/VennService";
import { SalesReportGraph } from "./SalesReportGraph";
import { SendPushNotificationView } from "./SendPushNotificationView";
import { useHistory } from "react-router-dom";

type AnalyticsScreenProps = {
  vennService: VennService;
  shop: string;
  handleSettingsChange: (settings: any) => void;
  settings: any;
};

export class AnalyticsScreen extends React.Component<AnalyticsScreenProps> {
  HomepageEditorNavigationCard = () => {
    const history = useHistory();

    const onClickGoToAppBuilder = () => {
      history.push("/builder", {
        queryParams: window.location.search,
      });
    };

    return (
      <Card
        sectioned
        title="Homepage Editor"
        primaryFooterAction={{
          content: "Customise Homepage",
          onAction: async () => {
            onClickGoToAppBuilder();
          },
        }}
      >
        <TextContainer>
          <p>
            The homepage editor allows you to update and preview the content on
            the mobile app homepage.
          </p>
        </TextContainer>
      </Card>
    );
  };

  ThemeNavigationCard = () => {
    const history = useHistory();

    const onClickGoToAppBuilder = () => {
      history.push("/theme", {
        queryParams: window.location.search,
      });
    };

    return (
      <Card
        sectioned
        title="Theme Editor"
        primaryFooterAction={{
          content: "Customise App Theme",
          onAction: async () => {
            onClickGoToAppBuilder();
          },
        }}
      >
        <TextContainer>
          <p>
            The theme editor allows you to change the look, feel and
            functionality of your mobile app.
          </p>
        </TextContainer>
      </Card>
    );
  };

  onClickGoToVenn = () => {
    window.open("https://vennapps.com");
  };

  onClickGoToAppSubmission = () => {
    window.open("https://vennapps.com/app-submission-process/");
  };

  onClickLaunchIntercom = () => {
    (window as any).Intercom("showNewMessage");
  };

  render() {
    return (
      <Page title="Venn Apps">
        <Layout>
          <Layout.Section>
            <div
              style={
                this.props.settings.isOnboarding
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Card title="Welcome to Venn Apps! 👋" sectioned>
                <FormLayout>
                  <TextContainer>
                    <p>
                      You’ve successfully installed the Shopify app, and you are
                      now in development mode.
                      <br />
                      <br />
                      You can edit the way your mobile app will look and feel
                      using the Homepage and Theme Editors in the dashboard.
                      <br />
                      <br />
                      <b>
                        Step 1: Build your homepage using the Homepage Editor
                      </b>
                      <ul>
                        <li>
                          Use the Homepage Editor to upload your company banner
                          assets onto the homepage of the app
                        </li>
                        <li>
                          It is also possible to connect your homepage banners
                          to corresponding products or collections
                        </li>
                        <li>Remember to save each of your asset updates</li>
                      </ul>
                      <b>Step 2: Update your theme using the Theme Editor</b>
                      <ul>
                        <li>
                          Going through the options in the Theme Editor will
                          define the look and feel of you app.
                        </li>
                        <li>
                          Once you have gone through all of the options click
                          ‘save’
                        </li>
                      </ul>
                      <b>Step 3: Launch</b>
                      <ul>
                        <li>
                          Once you have saved your homepage & theme updates
                          please{" "}
                          <Link onClick={this.onClickLaunchIntercom}>
                            <b>contact the Venn Apps team</b>
                          </Link>{" "}
                          via Intercom to let them know you have completed the
                          configuration.
                        </li>
                      </ul>
                    </p>
                    <p>
                      For more information on the submission process&nbsp;
                      <Link onClick={this.onClickGoToAppSubmission}>
                        click here
                      </Link>
                      .
                    </p>
                  </TextContainer>
                </FormLayout>
              </Card>
            </div>
          </Layout.Section>
          <Layout.Section>
            <div
              style={
                this.props.settings.isOnboarding
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <this.HomepageEditorNavigationCard />
            </div>
          </Layout.Section>
          <Layout.Section>
            <div
              style={
                this.props.settings.isOnboarding
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <this.ThemeNavigationCard />
            </div>
          </Layout.Section>
          {/*<div*/}
          {/*  style={*/}
          {/*    this.props.settings.isOnboarding*/}
          {/*      ? { display: "none" }*/}
          {/*      : { display: "block" }*/}
          {/*  }*/}
          {/*>*/}
          <SalesReportGraph
            vennService={this.props.vennService}
            analyticsLink={this.props.settings.analyticsLink}
            currencySymbol={this.props.settings.currencySymbol}
            storekey={this.props.settings.storekey}
            settings={this.props.settings}
          />
          <SendPushNotificationView
            vennService={this.props.vennService}
            storekey={this.props.settings.storekey}
            pushLink={this.props.settings.pushLink}
            settings={this.props.settings}
          />
          {/*</div>*/}
          {/*<SmartBannerSetting*/}
          {/*  handleSettingsChange={this.props.handleSettingsChange}*/}
          {/*  settings={this.props.settings}*/}
          {/*  shop={this.props.shop}*/}
          {/*  vennService={this.props.vennService}*/}
          {/*/>*/}
          <Layout.Section>
            <FooterHelp>
              Learn more about{" "}
              <Link onClick={this.onClickGoToVenn}>Venn Apps</Link>
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}
