import React from "react";
import "@shopify/polaris/dist/styles.css";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Link,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { VennService } from "../service/VennService";
import "../appBuilder/MobilePhoneStyle.css";
import { deepCloneObject } from "../utils/ObjectUtils";

type ThemeScreenProps = {
  vennService: VennService;
  storekey: string;
  storeInfoInit: any;
  themeInit: any;
  queryParamsString: string;
};

type ThemeScreenState = {
  abandonedBasketPushNotification: string;
  quickAddV2: boolean;
  themeLatestSaved: any;
  storeInfoLatestSaved: any;
  productCellStyle: string;
  basketCellStyle: string;
  splitDiscover: boolean;
  darkTheme: boolean;
};

export class ThemeScreen extends React.Component<
  ThemeScreenProps,
  ThemeScreenState
> {
  constructor(props: ThemeScreenProps) {
    super(props);

    const navBarBackgroundColor = this.props.themeInit.navBarBackgroundColor
      ?.hex;
    const isDarkTheme =
      navBarBackgroundColor && navBarBackgroundColor === "#000000";

    this.state = {
      darkTheme: isDarkTheme,
      storeInfoLatestSaved: props.storeInfoInit,
      themeLatestSaved: props.themeInit,
      quickAddV2: props.storeInfoInit.quickAddV2 || false,
      productCellStyle: props.storeInfoInit.productCellStyle || "",
      basketCellStyle: props.storeInfoInit.basketCellStyle || "",
      splitDiscover: props.storeInfoInit.splitDiscover || false,
      abandonedBasketPushNotification:
        props.storeInfoInit.abandonedBasketPushNotification || "",
    };
  }

  handlePushMessageChange = (newValue: string) => {
    this.setState({ abandonedBasketPushNotification: newValue });
  };

  handleQuickAddChange = (enabled: boolean) => {
    this.setState({ quickAddV2: enabled });
  };

  productCellTypeSelected = (checked: boolean, selected: string) => {
    if (checked) {
      this.setState({ productCellStyle: selected });
    }
  };

  basketCellTypeSelected = (checked: boolean, selected: string) => {
    if (checked) {
      this.setState({ basketCellStyle: selected });
    }
  };

  splitDiscoverTypeSelected = (checked: boolean, selected: string) => {
    if (checked) {
      if (selected === "sideTabs") {
        this.setState({ splitDiscover: true });
      }
      if (selected === "topTabs") {
        this.setState({ splitDiscover: false });
      }
    }
  };

  themeTypeSelected = (checked: boolean, selected: string) => {
    if (checked) {
      if (selected === "light") {
        this.setState({ darkTheme: false });
      }
      if (selected === "dark") {
        this.setState({ darkTheme: true });
      }
    }
  };

  onClickLaunchIntercom = () => {
    (window as any).Intercom("showNewMessage");
  };

  onSaveButtonClicked = async () => {
    const storeInfoClone = deepCloneObject(this.state.storeInfoLatestSaved);
    storeInfoClone.abandonedBasketPushNotification = this.state.abandonedBasketPushNotification;
    storeInfoClone.quickAddV2 = this.state.quickAddV2;
    storeInfoClone.productCellStyle = this.state.productCellStyle;
    storeInfoClone.basketV2 = true;
    storeInfoClone.basketCellStyle = this.state.basketCellStyle;
    storeInfoClone.splitDiscover = this.state.splitDiscover;
    storeInfoClone.splitParentStyle = "text";

    await this.props.vennService.updateStoreInfo(
      this.props.storekey,
      storeInfoClone
    );
    this.setState({ storeInfoLatestSaved: storeInfoClone });

    const themeClone = deepCloneObject(this.state.themeLatestSaved);
    if (this.state.darkTheme) {
      themeClone.navBarBackgroundColor = { hex: "#000000" };
      themeClone.tabBarBackgroundColor = { hex: "#000000" };
      themeClone.navigationBarTitleColor = { hex: "#ffffff" };
      themeClone.tabBarTintColor = { hex: "#ffffff" };
    } else {
      themeClone.navBarBackgroundColor = { hex: "#ffffff" };
      themeClone.tabBarBackgroundColor = { hex: "#ffffff" };
      themeClone.navigationBarTitleColor = { hex: "#000000" };
      themeClone.tabBarTintColor = { hex: "#000000" };
    }
    await this.props.vennService.updateTheme(this.props.storekey, themeClone);
    console.log("Updating state to new theme");
    this.setState({ themeLatestSaved: themeClone });
  };

  changesHaveHappened(): boolean {
    const navBarBackgroundColor = this.state.themeLatestSaved
      .navBarBackgroundColor?.hex;
    const isDarkTheme =
      navBarBackgroundColor && navBarBackgroundColor === "#000000";
    const themeChanged = isDarkTheme !== this.state.darkTheme;

    const quickAddChanged =
      (this.state.storeInfoLatestSaved.quickAddV2 || false) !==
      this.state.quickAddV2;

    return (
      this.state.storeInfoLatestSaved.abandonedBasketPushNotification !==
        this.state.abandonedBasketPushNotification ||
      this.state.storeInfoLatestSaved.productCellStyle !==
        this.state.productCellStyle ||
      this.state.storeInfoLatestSaved.basketCellStyle !==
        this.state.basketCellStyle ||
      this.state.storeInfoLatestSaved.splitDiscover !==
        this.state.splitDiscover ||
      quickAddChanged ||
      themeChanged
    );
  }

  render() {
    let productCellStyleDemoImage =
      "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FStyle%201%20Product%20Collection.jpg?alt=media&token=6fe43cea-ade1-45b7-b1e2-6dfde352a091";
    if (this.state.productCellStyle === "reversed") {
      productCellStyleDemoImage =
        "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FStyle%203%20Product%20Collection.jpg?alt=media&token=885bb55d-a096-4755-8cd7-e90b41f29d3f";
    }
    if (this.state.productCellStyle === "standardV2") {
      productCellStyleDemoImage =
        "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FStyle%204%20-Product%20Collection.jpg?alt=media&token=c8da705d-0af1-4769-bffd-37f67f78962b";
    }
    if (this.state.productCellStyle === "reversedImage") {
      productCellStyleDemoImage =
        "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FStyle%202%20Product%20Collection.jpg?alt=media&token=6f79f780-a517-4d2a-b02d-4b1b15c5746b";
    }

    let basketCellStyleDemoImage =
      "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2Fstyle%201%20basket.jpg?alt=media&token=e304ea33-dbc6-4661-9463-dedde15b1c8e";
    if (this.state.basketCellStyle === "v2Reversed") {
      basketCellStyleDemoImage =
        "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2Fstyle%202%20basket.jpg?alt=media&token=cf7110c3-336f-4bd0-ba65-e8de7ecd2c90";
    }

    let splitDiscoverImage =
      "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FList.jpg?alt=media&token=59951de5-282a-4086-b0b3-ca2d9c459937";
    if (this.state.splitDiscover) {
      splitDiscoverImage =
        "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2FDuo%20Screen.jpg?alt=media&token=6f6b7afe-abba-4dc8-acc1-5ab2565c7fa3";
    }

    let themeImage = this.state.darkTheme
      ? "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2Fdarkblack.png?alt=media&token=eba4db47-4f35-40cd-822a-d92f1e7b2d7e"
      : "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2Flightwhite.png?alt=media&token=ed12f74d-9797-4103-96f1-513b9db08d36";

    return (
      <Page
        title="Theme Editor"
        breadcrumbs={[
          {
            content: "Venn Apps Dashboard",
            url: `/${this.props.queryParamsString}`,
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card title="Theme Editor" sectioned>
              <FormLayout>
                <TextContainer>
                  <p>
                    You can use this tool to edit the theme in your mobile app.
                    Define the look, feel, and functionality of the mobile app
                    using the options below. Once you have selected options
                    click the Save button at the bottom.
                  </p>
                  <p>
                    <b>Please note:</b> Updates can take up to 10 minutes to
                    reflect on your mobile app. You can refresh your mobile app
                    by force closing it and re-opening it.
                  </p>
                  <p>
                    Please{" "}
                    <Link onClick={this.onClickLaunchIntercom}>
                      <b>get in touch</b>
                    </Link>{" "}
                    if you require any assistance updating your theme.
                  </p>
                </TextContainer>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Overall app theme">
              <FormLayout>
                <TextContainer>
                  <p>Select the overall theme for the app.</p>
                </TextContainer>

                <img style={{ width: "100%" }} src={themeImage} alt={""} />
                <Stack vertical>
                  <RadioButton
                    label="Light theme"
                    helpText="The app will have a light theme."
                    checked={!this.state.darkTheme}
                    id="light"
                    name="themeType"
                    onChange={this.themeTypeSelected}
                  />
                  <RadioButton
                    label="Dark theme"
                    helpText="The app will have a dark theme."
                    checked={this.state.darkTheme}
                    id="dark"
                    name="themeType"
                    onChange={this.themeTypeSelected}
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Quick add to basket">
              <FormLayout>
                <TextContainer>
                  <p>
                    When enabled, customers will be able to add a product to the
                    basket directly from the Product Listing Page of the app
                    without going to the Product Detail Page.
                  </p>
                </TextContainer>
                <Checkbox
                  label="Enable quick add to basket"
                  checked={this.state.quickAddV2}
                  onChange={this.handleQuickAddChange}
                />
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Product Listing Page Cell Style">
              <FormLayout>
                <TextContainer>
                  <p>
                    Select how the products will be displayed on the app Product
                    Listing page.
                  </p>
                </TextContainer>

                <img
                  style={{ width: "30%" }}
                  src={productCellStyleDemoImage}
                  alt={""}
                />
                <Stack vertical>
                  <RadioButton
                    label="Style 1"
                    helpText="Left align text with the price and wishlist button below the image and the product name below"
                    checked={this.state.productCellStyle === "standard"}
                    id="standard"
                    name="productCellStyle"
                    onChange={this.productCellTypeSelected}
                  />
                  <RadioButton
                    label="Style 2"
                    helpText="Left align text with the product name and wishlist button below the image and the price below"
                    checked={this.state.productCellStyle === "reversedImage"}
                    id="reversedImage"
                    name="productCellStyle"
                    onChange={this.productCellTypeSelected}
                  />
                  <RadioButton
                    label="Style 3"
                    helpText="Left align text with the product name below the image and the price below. Wishlist button to the top right"
                    checked={this.state.productCellStyle === "reversed"}
                    id="reversed"
                    name="productCellStyle"
                    onChange={this.productCellTypeSelected}
                  />
                  <RadioButton
                    label="Style 4"
                    helpText="Center align the product name and price. Wishlist button to the top right"
                    checked={this.state.productCellStyle === "standardV2"}
                    id="standardV2"
                    name="productCellStyle"
                    onChange={this.productCellTypeSelected}
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Basket Cell Style">
              <FormLayout>
                <TextContainer>
                  <p>Select how the products are displayed in the basket.</p>
                </TextContainer>

                <img
                  style={{ width: "30%" }}
                  src={basketCellStyleDemoImage}
                  alt={""}
                />
                <Stack vertical>
                  <RadioButton
                    label="Price above"
                    helpText="The price goes above the product name."
                    checked={this.state.basketCellStyle === "v2"}
                    id="v2"
                    name="basketCellStyle"
                    onChange={this.basketCellTypeSelected}
                  />
                  <RadioButton
                    label="Price below"
                    helpText="The price goes below the product name."
                    checked={this.state.basketCellStyle === "v2Reversed"}
                    id="v2Reversed"
                    name="basketCellStyle"
                    onChange={this.basketCellTypeSelected}
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Product Discovery Page Style">
              <FormLayout>
                <TextContainer>
                  <p>Select how the product discovery page looks.</p>
                </TextContainer>

                <img
                  style={{ width: "30%" }}
                  src={splitDiscoverImage}
                  alt={""}
                />
                <Stack vertical>
                  <RadioButton
                    label="Tabs on top"
                    helpText="The top level menus are used to create tabs at the top of the screen."
                    checked={!this.state.splitDiscover}
                    id="topTabs"
                    name="splitDiscoverStyle"
                    onChange={this.splitDiscoverTypeSelected}
                  />
                  <RadioButton
                    label="Tabs on the side"
                    helpText="The top level menus are used to create tabs at the top of the screen."
                    checked={this.state.splitDiscover}
                    id="sideTabs"
                    name="splitDiscoverStyle"
                    onChange={this.splitDiscoverTypeSelected}
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned title="Abandoned Basket Push Notfication">
              <FormLayout>
                <TextContainer>
                  <p>
                    Set the push notification message that customers will
                    receive when they close the app with a product in their
                    basket.
                  </p>
                </TextContainer>
                <TextField
                  placeholder="Push notification message"
                  label="Push notification message"
                  labelHidden={true}
                  value={this.state.abandonedBasketPushNotification}
                  onChange={this.handlePushMessageChange}
                />
              </FormLayout>
            </Card>
          </Layout.Section>
        </Layout>
        <PageActions
          primaryAction={{
            content: "Save",
            disabled: !this.changesHaveHappened(),
            onAction: async () => {
              await this.onSaveButtonClicked();
            },
          }}
        />
      </Page>
    );
  }
}
