import React from "react";
import "@shopify/polaris/dist/styles.css";
import {
  Card,
  FormLayout,
  Layout,
  Link,
  Page,
  TextContainer,
} from "@shopify/polaris";
import { VennService } from "../service/VennService";
import "./MobilePhoneStyle.css";
import "./AppBuilderScreen.css";
import { HomepageRenderView } from "./HomepageRenderView";
import { EditModuleView } from "./EditModuleView";
import { areEqualObjects, deepCloneObject } from "../utils/ObjectUtils";
import firebase from "firebase";
import { EditTopLogoView } from "./EditTopLogoView";

type AppBuilderScreenProps = {
  vennService: VennService;
  storekey: string;
  runningLocally: boolean;
  firebaseClient: firebase.app.App;
  homepageInit: any;
  storeInfoInit: any;
  handleHomepageChange: (homepage: any) => void;
  handleStoreInfoChange: (homepage: any) => void;
  queryParamsString: string;
};

type AppBuilderScreenState = {
  storeInfo: any;
  homepageSelectedIndex: number;
  moduleSelectedIndex: number;
  isTopLogoSelected: boolean;
  homepages: any[];
};

export class AppBuilderScreen extends React.Component<
  AppBuilderScreenProps,
  AppBuilderScreenState
> {
  constructor(props: AppBuilderScreenProps) {
    super(props);
    console.log(`homepages: ${JSON.stringify(this.props.homepageInit)}`);
    let homepages: any[] = this.props.homepageInit.homepages
      ? this.props.homepageInit.homepages
      : [this.props.homepageInit];

    this.state = {
      storeInfo: deepCloneObject(props.storeInfoInit),
      homepageSelectedIndex: 0,
      moduleSelectedIndex: -1,
      isTopLogoSelected: false,
      // create a copy of the homepages and edit that until save button is clicked and changes are saved
      homepages: deepCloneObject(homepages),
    };
  }

  componentDidMount(): void {}

  handleModuleSelectedChange = (moduleSelectedIndex: number) => {
    if (this.state.moduleSelectedIndex === moduleSelectedIndex) {
      this.setState({ isTopLogoSelected: false, moduleSelectedIndex: -1 });
    } else {
      this.setState({
        isTopLogoSelected: false,
        moduleSelectedIndex: moduleSelectedIndex,
      });
    }
  };

  handleModuleUpdate = (updatedModule: any) => {
    const homepagesClone: any[] = deepCloneObject(this.state.homepages);
    homepagesClone[this.state.homepageSelectedIndex][
      this.state.moduleSelectedIndex
    ] = updatedModule;
    this.setState({ homepages: homepagesClone });
  };

  handleTopLogoUpdate = (topLogoUrl: string) => {
    const storeInfoClone: any = deepCloneObject(this.state.storeInfo);
    storeInfoClone.topBarLogo = topLogoUrl;
    this.setState({ storeInfo: storeInfoClone });
  };

  onClickLaunchIntercom = () => {
    (window as any).Intercom("showNewMessage");
  };

  releaseHomepageChanges = async () => {
    if (this.hasHomepageChanged()) {
      const usesFlatHomepageStructure =
        this.props.homepageInit.homepages === undefined;
      const homepageUpdate = usesFlatHomepageStructure
        ? this.state.homepages[0]
        : this.state.homepages;
      await this.props.vennService.updateHomepage(
        this.props.storekey,
        homepageUpdate
      );
      this.props.handleHomepageChange(homepageUpdate);
    }
    if (this.hasStoreInfoChanged()) {
      await this.props.vennService.updateStoreInfo(
        this.props.storekey,
        this.state.storeInfo
      );
      this.props.handleStoreInfoChange(this.state.storeInfo);
    }
  };

  haveChangesHappened(): boolean {
    return this.hasHomepageChanged() || this.hasStoreInfoChanged();
  }

  hasHomepageChanged(): boolean {
    const homepages = this.props.homepageInit.homepages
      ? this.props.homepageInit.homepages
      : [this.props.homepageInit];
    const homepageHasChanged = !areEqualObjects(
      homepages,
      this.state.homepages
    );
    return homepageHasChanged;
  }

  hasStoreInfoChanged(): boolean {
    return !areEqualObjects(this.props.storeInfoInit, this.state.storeInfo);
  }

  render() {
    return (
      <Page
        title="Homepage Editor"
        fullWidth
        breadcrumbs={[
          {
            content: "Venn Apps Dashboard",
            url: `/${this.props.queryParamsString}`,
          },
        ]}
      >
        <Layout>
          <Layout.Section oneHalf>
            <Card
              title="Homepage Preview"
              primaryFooterAction={{
                content: "Save Updates",
                onAction: this.releaseHomepageChanges,
                disabled: !this.haveChangesHappened(),
              }}
            >
              <Card.Section>
                <div
                  style={{
                    width: "100%",
                    marginTop: "16px",
                    marginBottom: "32px",
                  }}
                >
                  <div style={{ margin: "auto", display: "table" }}>
                    <div className="marvel-device iphone-x">
                      <div className="notch">
                        <div className="camera"></div>
                        <div className="speaker"></div>
                      </div>
                      <div className="top-bar"></div>
                      <div className="sleep"></div>
                      <div className="bottom-bar"></div>
                      <div className="volume"></div>
                      <div className="overflow">
                        <div className="shadow shadow--tr"></div>
                        <div className="shadow shadow--tl"></div>
                        <div className="shadow shadow--br"></div>
                        <div className="shadow shadow--bl"></div>
                      </div>
                      <div className="inner-shadow"></div>
                      <div className="screen">
                        <div className="appContainer">
                          <div
                            className="appToolbar"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                this.state.storeInfo.topBarLogo
                                  ? this.state.storeInfo.topBarLogo
                                  : "https://firebasestorage.googleapis.com/v0/b/venn-prod-70222.appspot.com/o/dashboard-ui%2Fic_logo.png?alt=media&token=4796c571-b528-45f6-88cf-545f7374dca5"
                              }
                              className={
                                this.state.isTopLogoSelected ? "selected" : ""
                              }
                              onClick={() =>
                                this.setState({
                                  isTopLogoSelected: !this.state
                                    .isTopLogoSelected,
                                  moduleSelectedIndex: -1,
                                })
                              }
                              alt={""}
                              style={{
                                width: "75%",
                                marginTop: "64px",
                                marginBottom: "32px",
                              }}
                            />
                          </div>
                          <div className="appHomepageContent">
                            <HomepageRenderView
                              handleModuleSelectedChange={
                                this.handleModuleSelectedChange
                              }
                              moduleSelectedIndex={
                                this.state.moduleSelectedIndex
                              }
                              homepage={
                                this.state.homepages[
                                  this.state.homepageSelectedIndex
                                ]
                              }
                              runningLocally={this.props.runningLocally}
                            />
                          </div>
                          <div className="appBottomBar"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <div
              style={
                this.state.moduleSelectedIndex === -1 &&
                !this.state.isTopLogoSelected
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Card title="Homepage Editor" sectioned>
                <FormLayout>
                  <TextContainer>
                    <p>
                      You can use this tool to edit the homepage in your mobile
                      app. To make a change, click on the asset you want to
                      update in the simulator. You will then have the option to
                      upload a new asset and link it to either a collection or a
                      product. Remember to press the save button once you've
                      finalised your changes.
                    </p>
                    <p>
                      <b>Please note:</b> Updates can take up to 10 minutes to
                      reflect on your mobile app. You can refresh your mobile
                      app by force closing it and re-opening it.
                    </p>
                    <p>
                      Please{" "}
                      <Link onClick={this.onClickLaunchIntercom}>
                        <b>get in touch</b>
                      </Link>{" "}
                      if you require any assistance updating your homepage.
                    </p>
                  </TextContainer>
                </FormLayout>
              </Card>
            </div>
            <EditTopLogoView
              firebaseClient={this.props.firebaseClient}
              storekey={this.props.storekey}
              vennService={this.props.vennService}
              handleLogoUpdate={this.handleTopLogoUpdate}
              isTopLogoSelected={this.state.isTopLogoSelected}
              storeInfo={this.state.storeInfo}
            />
            <EditModuleView
              appModule={
                this.state.homepages[this.state.homepageSelectedIndex][
                  this.state.moduleSelectedIndex
                ]
              }
              handleModuleUpdate={this.handleModuleUpdate}
              firebaseClient={this.props.firebaseClient}
              storekey={this.props.storekey}
              vennService={this.props.vennService}
            />
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}
