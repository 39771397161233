import React from "react";
import "@shopify/polaris/dist/styles.css";
import {
  Card,
  Form,
  FormLayout,
  Layout,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { VennService } from "../service/VennService";

type SendPushNotificationViewProps = {
  vennService: VennService;
  storekey: string;
  pushLink: string;
  settings: any;
};

type SendPushNotificationViewState = {
  pushMessageValue: string;
};

export class SendPushNotificationView extends React.Component<
  SendPushNotificationViewProps,
  SendPushNotificationViewState
> {
  constructor(props: SendPushNotificationViewProps) {
    super(props);
    this.state = { pushMessageValue: "" };
  }

  onClickGoToAdvancedPush = () => {
    if (this.props.settings.isOnboarding) {
      alert(
        "This feature will become functional once the mobile app goes live."
      );
    } else {
      window.open(this.props.pushLink);
    }
  };

  render() {
    return (
      <Layout.Section>
        <Card
          sectioned
          title="Quick Send Push Notification"
          primaryFooterAction={{
            content: "Send",
            onAction: async () => {
              await this.pushSubmit();
            },
          }}
          secondaryFooterActions={[
            {
              content: "Advanced Push",
              onAction: this.onClickGoToAdvancedPush,
            },
          ]}
        >
          <Form onSubmit={this.pushSubmit}>
            <FormLayout>
              <TextContainer>
                <p>
                  Fill out the content for your push notifcation in the field
                  below and press send when you are ready to send it to all of
                  your users.
                </p>
              </TextContainer>
              <TextField
                placeholder="Push notification message"
                label="Push notification message"
                labelHidden={true}
                value={this.state.pushMessageValue}
                onChange={this.handlePushMessageChange}
              />
            </FormLayout>
          </Form>
        </Card>
      </Layout.Section>
    );
  }

  handlePushMessageChange = (newValue: string) => {
    this.setState({ pushMessageValue: newValue });
  };

  pushSubmit = async () => {
    if (this.props.settings.isOnboarding) {
      alert(
        "This feature will become functional once the mobile app goes live."
      );
    } else {
      await this.props.vennService.sendPushToAll(
        this.props.storekey,
        this.state.pushMessageValue,
        ""
      );
      alert("Success! Your push notification has been sent.");
      this.setState({ pushMessageValue: "" });
    }
  };
}
