import React from "react";
import "@shopify/polaris/dist/styles.css";
import "./EditModuleView.css";
import { EditPlaceholderView } from "./EditPlaceholderView";
import { EditImageWithPaddingView } from "./EditImageWithPaddingView";
import firebase from "firebase";
import { VennService } from "../service/VennService";
import { EditSpacerView } from "./EditSpacerView";

type EditModuleViewProps = {
  appModule: any;
  storekey: string;
  vennService: VennService;
  firebaseClient: firebase.app.App;
  handleModuleUpdate: (updatedModule: any) => void;
};

export class EditModuleView extends React.Component<EditModuleViewProps> {
  render() {
    if (!this.props.appModule) {
      return null;
    }
    const moduleType = this.props.appModule.moduleType;
    let moduleEditorView: any;
    switch (moduleType) {
      case "VImageWithPadding":
        moduleEditorView = (
          <EditImageWithPaddingView
            storekey={this.props.storekey}
            vennService={this.props.vennService}
            initialAppModule={this.props.appModule}
            handleModuleUpdate={this.props.handleModuleUpdate}
            firebaseClient={this.props.firebaseClient}
          />
        );
        break;
      case "VSpacer":
        moduleEditorView = (
          <EditSpacerView
            initialAppModule={this.props.appModule}
            handleModuleUpdate={this.props.handleModuleUpdate}
          />
        );
        break;
      default:
        moduleEditorView = (
          <EditPlaceholderView
            appModule={this.props.appModule}
            handleModuleUpdate={this.props.handleModuleUpdate}
          />
        );
    }

    return moduleEditorView;
  }
}
