import React from "react";
import "@shopify/polaris/dist/styles.css";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import "./EditModuleView.css";
import { areEqualObjects, deepCloneObject } from "../utils/ObjectUtils";

type EditSpacerViewProps = {
  initialAppModule: any;
  handleModuleUpdate: (updatedModule: any) => void;
};

type EditSpacerViewState = {
  currentAppModule: any;
};

export class EditSpacerView extends React.Component<
  EditSpacerViewProps,
  EditSpacerViewState
> {
  constructor(props: EditSpacerViewProps) {
    super(props);
    this.state = {
      currentAppModule: props.initialAppModule,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(
    prevProps: Readonly<EditSpacerViewProps>,
    prevState: Readonly<EditSpacerViewState>,
    snapshot?: any
  ) {
    if (
      !areEqualObjects(prevProps.initialAppModule, this.props.initialAppModule)
    ) {
      this.setState({ currentAppModule: this.props.initialAppModule });
    }
  }

  handlePreviewButtonClicked = async () => {
    const currentAppModule = deepCloneObject(this.state.currentAppModule);
    console.log(`Saving module value: ${JSON.stringify(currentAppModule)}`);
    this.props.handleModuleUpdate(currentAppModule);
  };

  handleHeightChange = (value: string) => {
    console.log(`Saving height: ${value}`);
    const updatedObject = deepCloneObject(this.state.currentAppModule);
    updatedObject.height = +value;
    this.setState({ currentAppModule: updatedObject });
  };

  render() {
    const moduleHasChanged = !areEqualObjects(
      this.props.initialAppModule,
      this.state.currentAppModule
    );
    const this1 = this;
    return (
      <Card
        title={"Edit Banner Image View"}
        sectioned
        secondaryFooterActions={[
          {
            content: "Preview Updates",
            onAction(): void {
              this1.handlePreviewButtonClicked();
            },
            disabled: !moduleHasChanged,
          },
        ]}
      >
        <div>
          <FormLayout>
            <TextField
              value={`${this.state.currentAppModule.height}`}
              onChange={this.handleHeightChange}
              label="Spacer Height"
              type="number"
            />
          </FormLayout>
        </div>
      </Card>
    );
  }
}
