import axios from "axios";

export class VennService {
  baseUrl = "https://api.vennapps.com";

  constructor(runningLocally: boolean, private queryParams: string) {
    if (runningLocally) {
      this.baseUrl = "http://localhost:5000";
    }
  }

  async getSettings(shop: string): Promise<any> {
    try {
      console.log(`getSettings called with shop ${shop}`);
      const url = `${this.baseUrl}/settings?shop=${shop}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getHomepage(storekey: string): Promise<any> {
    try {
      console.log(`getHomepage called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/get-homepage`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getStoreInfo(storekey: string): Promise<any> {
    try {
      console.log(`getStoreInfo called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/get-store-info`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async updateStoreInfo(storekey: string, storeinfo: any): Promise<any> {
    try {
      console.log(`updateStoreInfo called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/update-store-info`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.post(url, storeinfo, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getTheme(storekey: string): Promise<any> {
    try {
      console.log(`getTheme called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/get-theme-info`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async updateTheme(storekey: string, storeinfo: any): Promise<any> {
    try {
      console.log(`updateTheme called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/update-theme-info`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.post(url, storeinfo, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async updateHomepage(storekey: string, homepage: any): Promise<any> {
    try {
      console.log(`updateHomepage called with storekey ${storekey}`);
      const url = `${this.baseUrl}/dashboard/update-homepage`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
          shopifyRequestUrl: this.queryParams,
        },
      };
      const requestData = await axios.post(url, homepage, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getSalesReport(storekey: string): Promise<any> {
    try {
      console.log(`getSalesReport called with storekey: ${storekey}`);
      const url = `${this.baseUrl}/shopify/sales-report?storekey=${storekey}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getProductByHandle(storekey: string, handle: string): Promise<any> {
    try {
      console.log(
        `getProductIdByHandle called with storekey: ${storekey} handle: ${handle}`
      );
      const url = `${this.baseUrl}/shopify/product?handle=${handle}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getProductById(storekey: string, id: string): Promise<any> {
    try {
      console.log(
        `getProductIdById called with storekey: ${storekey} id: ${id}`
      );
      const url = `${this.baseUrl}/shopify/product?id=${id}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getCollectionById(storekey: string, id: string): Promise<any> {
    try {
      console.log(
        `getCollectionIdById called with storekey: ${storekey} id: ${id}`
      );
      const url = `${this.baseUrl}/shopify/category?id=${id}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const requestData = await axios.get(url, headers);
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async getCollectionByHandle(storekey: string, handle: string): Promise<any> {
    try {
      console.log(
        `getCollectionIdByHandle called with storekey: ${storekey} handle: ${handle}`
      );
      const url = `${this.baseUrl}/shopify/category?handle=${handle}`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const requestData = await axios.get(url, headers);
      console.log(
        `getCollectionIdByHandle received: ${JSON.stringify(requestData.data)}`
      );
      return requestData.data;
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async sendPushToAll(storekey: string, message: string, deeplink: string) {
    try {
      console.log(
        `sendPushToAll called with storekey: ${storekey}, message: ${message}, deeplink: ${deeplink}`
      );
      const url = `${this.baseUrl}/firebase/push/all`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
          storekey: storekey,
        },
      };
      const body = {
        message: message,
        deeplink: deeplink,
      };
      await axios.post(url, body, headers);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  async setSmartBannerSettings(shop: string, enableDeeplinks: boolean) {
    try {
      console.log(
        `setSmartBannerSettings called with shop: ${shop}, enableDeeplinks: ${enableDeeplinks}`
      );
      const url = `${this.baseUrl}/settings`;
      const headers = {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Content-Type": "application/json",
        },
      };
      const body = {
        shop: shop,
        enableDeeplinks: enableDeeplinks,
      };
      await axios.post(url, body, headers);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }
}
