import React from "react";
import "@shopify/polaris/dist/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame, Layout, Loading, Page } from "@shopify/polaris";
import { VennService } from "../service/VennService";
import queryString from "query-string";
import { ThemeScreen } from "./ThemeScreen";

type ThemeScreenRootProps = {
  location: any;
};

type ThemeScreenRootState = {
  settings: any;
  storeInfo: any;
  theme: any;
  storekey: string;
};

export class ThemeScreenRoot extends React.Component<
  ThemeScreenRootProps,
  ThemeScreenRootState
> {
  queryParamsString =
    this.props.location.state.queryParams || window.location.search;
  queryParams = queryString.parse(this.queryParamsString);
  isRunningLocally =
    this.queryParams.bypassSecurity !== undefined &&
    this.queryParams.isRunningLocally === "true";
  shop = this.queryParams.shop as string;
  vennService = new VennService(this.isRunningLocally, this.queryParamsString);

  constructor(props: ThemeScreenRootProps) {
    super(props);
    this.state = {
      settings: undefined,
      storeInfo: undefined,
      theme: undefined,
      storekey: "",
    };
  }

  async componentDidMount() {
    const settings = await this.vennService.getSettings(this.shop);
    const storeInfo = await this.vennService.getStoreInfo(settings.storekey);
    const theme = await this.vennService.getTheme(settings.storekey);
    this.setState({
      settings: settings,
      storeInfo: storeInfo,
      theme: theme,
      storekey: settings.storekey,
    });
  }

  render() {
    if (!this.state.settings) {
      // show loading bar if settings are still being fetched
      return (
        <AppProvider i18n={enTranslations}>
          <Page title="Venn Apps">
            <Layout>
              <Frame>
                <Loading />
              </Frame>
            </Layout>
          </Page>
        </AppProvider>
      );
    }
    return (
      <AppProvider i18n={enTranslations}>
        <ThemeScreen
          vennService={this.vennService}
          storekey={this.state.storekey}
          queryParamsString={this.queryParamsString}
          storeInfoInit={this.state.storeInfo}
          themeInit={this.state.theme}
        />
      </AppProvider>
    );
  }
}
