import React from "react";
import "@shopify/polaris/dist/styles.css";
import { PlaceholderModuleView } from "./PlaceholderModuleView";
import { ImageWithPaddingView } from "./ImageWithPaddingView";
import { SpacerView } from "./SpacerView";

type HomepageRenderViewProps = {
  homepage: any[];
  runningLocally: boolean;
  moduleSelectedIndex: number;
  handleModuleSelectedChange: (moduleSelectedIndex: number) => void;
};

export class HomepageRenderView extends React.Component<
  HomepageRenderViewProps
> {
  render() {
    return this.renderModules();
  }

  renderModules() {
    const moduleViews = [];

    for (
      let appModuleIndex = 0;
      appModuleIndex < this.props.homepage.length;
      appModuleIndex++
    ) {
      const appModule = this.props.homepage[appModuleIndex];
      switch (appModule.moduleType) {
        case "VSpacer":
          moduleViews.push(
            <div
              onClick={() =>
                this.props.handleModuleSelectedChange(appModuleIndex)
              }
            >
              <SpacerView
                key={`moduleView-${appModuleIndex}`}
                appModule={appModule}
                isSelected={this.props.moduleSelectedIndex === appModuleIndex}
              />
            </div>
          );
          break;
        case "VImageWithPadding":
          moduleViews.push(
            <div
              onClick={() =>
                this.props.handleModuleSelectedChange(appModuleIndex)
              }
            >
              <ImageWithPaddingView
                key={`moduleView-${appModuleIndex}`}
                appModule={appModule}
                isSelected={this.props.moduleSelectedIndex === appModuleIndex}
              />
            </div>
          );
          break;
        default:
          if (this.props.runningLocally) {
            moduleViews.push(
              <div
                onClick={() =>
                  this.props.handleModuleSelectedChange(appModuleIndex)
                }
              >
                <PlaceholderModuleView
                  key={`moduleView-${appModuleIndex}`}
                  appModule={appModule}
                  isSelected={this.props.moduleSelectedIndex === appModuleIndex}
                />
              </div>
            );
          }
      }
    }

    return moduleViews;
  }
}
