import React from "react";
import "@shopify/polaris/dist/styles.css";
import "./PlaceholderModuleView.css";
import "./ModuleView.css";

type PlaceholderModuleViewProps = {
  appModule: any;
  isSelected: boolean;
};

export class PlaceholderModuleView extends React.Component<
  PlaceholderModuleViewProps
> {
  render() {
    let classes = "placeholderModuleContainer";
    if (this.props.isSelected) {
      classes = classes + " selected";
    } else {
      classes = classes + " unselected";
    }
    return <div className={classes}><div><span className={"placeholderModuleLabel"}>{this.props.appModule.moduleType}</span></div></div>;
  }
}
