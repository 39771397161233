import * as assert from "assert";

export const deepCloneObject = (typescriptObject: any) => {
  if (typescriptObject === undefined) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(typescriptObject));
};

export const areEqualObjects = (
  typescriptObject1: any,
  typescriptObject2: any,
  ignoreProperties?: string[]
): boolean => {
  let areEqual = true;

  // Parsing an undefined object into JSON.parse throws and error so check for undefined
  if (typescriptObject1 === undefined || typescriptObject2 === undefined) {
    if (typescriptObject1 === undefined && typescriptObject2 === undefined) {
      return true;
    }
    return false;
  } else {
    const javascriptObject1 = deepCloneObject(typescriptObject1);
    const javascriptObject2 = deepCloneObject(typescriptObject2);
    if (ignoreProperties && ignoreProperties.length > 0) {
      for (const ignoreProperty of ignoreProperties) {
        javascriptObject1[ignoreProperty] = undefined;
        javascriptObject2[ignoreProperty] = undefined;
      }
    }
    // also hacky but it works
    try {
      assert.deepStrictEqual(javascriptObject1, javascriptObject2);
    } catch (e) {
      areEqual = false;
    }
    if (!areEqual) {
      // console.log(`Objects are not equal`);
      // console.log(`typescriptObject1: ${JSON.stringify(typescriptObject1)}`);
      // console.log(stypescriptObject2: ${JSON.stringify(typescriptObject2)}`);
    }
    return areEqual;
  }
};
