import React from "react";
import "@shopify/polaris/dist/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame, Layout, Loading, Page } from "@shopify/polaris";
import { VennService } from "../service/VennService";
import { AppBuilderScreen } from "./AppBuilderScreen";
import firebase from "firebase";
import queryString from "query-string";
import { FirebaseSingleton } from "../common/FirebaseSingleton";

type AppBuilderScreenRootProps = {
  location: any;
};

type AppBuilderScreenRootState = {
  settings: any;
  homepage: any;
  storeInfo: any;
  storekey: string;
  firebaseClient: firebase.app.App | undefined;
};

export class AppBuilderScreenRoot extends React.Component<
  AppBuilderScreenRootProps,
  AppBuilderScreenRootState
> {
  queryParamsString =
    this.props.location.state.queryParams || window.location.search;
  queryParams = queryString.parse(this.queryParamsString);
  isRunningLocally =
    this.queryParams.bypassSecurity !== undefined &&
    this.queryParams.isRunningLocally === "true";
  shop = this.queryParams.shop as string;
  vennService = new VennService(this.isRunningLocally, this.queryParamsString);

  constructor(props: AppBuilderScreenRootProps) {
    super(props);
    this.state = {
      settings: undefined,
      homepage: undefined,
      storeInfo: undefined,
      storekey: "",
      firebaseClient: undefined,
    };
  }

  handleHomepageChange = (homepage: string) => {
    this.setState({ homepage: homepage });
  };

  handleStoreInfoChange = (storeInfo: string) => {
    this.setState({ storeInfo: storeInfo });
  };

  async componentDidMount() {
    const settings = await this.vennService.getSettings(this.shop);
    const homepage = await this.vennService.getHomepage(settings.storekey);
    const storeInfo = await this.vennService.getStoreInfo(settings.storekey);
    const firebaseClient = FirebaseSingleton.getInstance(settings.firebase);

    this.setState({
      settings: settings,
      homepage: homepage,
      storeInfo: storeInfo,
      firebaseClient: firebaseClient,
      storekey: settings.storekey,
    });
  }

  render() {
    if (!this.state.settings) {
      // show loading bar if settings are still being fetched
      return (
        <AppProvider i18n={enTranslations}>
          <Page title="Venn Apps">
            <Layout>
              <Frame>
                <Loading />
              </Frame>
            </Layout>
          </Page>
        </AppProvider>
      );
    }
    return (
      <AppProvider i18n={enTranslations}>
        <AppBuilderScreen
          vennService={this.vennService}
          handleHomepageChange={this.handleHomepageChange}
          homepageInit={this.state.homepage}
          firebaseClient={this.state.firebaseClient as firebase.app.App}
          storekey={this.state.storekey}
          runningLocally={this.isRunningLocally}
          queryParamsString={this.queryParamsString}
          storeInfoInit={this.state.storeInfo}
          handleStoreInfoChange={this.handleStoreInfoChange}
        />
      </AppProvider>
    );
  }
}
