import React from "react";
import "@shopify/polaris/dist/styles.css";
import { Route, Switch } from "react-router-dom";
import { AnalyticsScreenRoot } from "./analytics/AnalyticsScreenRoot";
import { AppBuilderScreenRoot } from "./appBuilder/AppBuilderScreenRoot";
import { ThemeScreenRoot } from "./theme/ThemeScreenRoot";

export class App extends React.Component {
  render() {
    return (
      <main>
        <Switch>
          <Route path="/" component={AnalyticsScreenRoot} exact />
          <Route path="/builder" component={AppBuilderScreenRoot} />
          <Route path="/theme" component={ThemeScreenRoot} />
        </Switch>
      </main>
    );
  }
}
